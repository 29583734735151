<template>
  <v-dialog
    v-model="innerValue"
    transition="slide-y-transition"
    class="elevation-0"
    scrollable
    persistent
    max-width="700px"
  >
    <v-card>
      <v-card-title class="grey lighten-4">
        <v-icon size="28" class="mr-3" color="info">mdi-alert-circle</v-icon>
        <span>{{ $t("hr.performance.info_dialog_title") }}</span>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="grey lighten-1" icon @click="onCloseClick">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("buttons.close") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="mt-4">
            <span class="px-2 v-label theme--light">
              {{ $t("hr.performance.info_dialog_message") }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "InfoDialog",
    props: {
      value: Boolean
    },
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    },
    methods: {
      onCloseClick() {
        this.innerValue = false;
      }
    }
  };
</script>
